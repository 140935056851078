/** @jsx jsx */

import React from "react" // eslint-disable-line no-unused-vars
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Box from "./box"
import Label from "./label"
import Icon from "./icon"

const CheckboxIcon = (props) => {
  return (
    <React.Fragment>
      <Icon
        icon="checkbox-checked"
        {...props}
        sx={{
          display: "none",
          "input:checked ~ &": {
            display: "block",
          },
        }}
      />
      <Icon
        icon="checkbox-unchecked"
        {...props}
        sx={{
          display: "block",
          "input:checked ~ &": {
            display: "none",
          },
        }}
      />
    </React.Fragment>
  )
}

const Checkbox = ({ children, color, space, ref, ...restProps }) => {
  return (
    <Label display="flex" alignY="center">
      <Box
        ref={ref}
        as="input"
        type="checkbox"
        {...restProps}
        sx={{
          position: "absolute",
          opacity: 0,
          zIndex: -1,
          width: 1,
          height: 1,
          overflow: "hidden",
        }}
      />
      <CheckboxIcon
        aria-hidden="true"
        sx={{
          flexShrink: 0,
          borderRadius: "md",
          cursor: "pointer",
          mr: children ? space : null,
          color: "whisper",
          "input:checked ~ &": {
            color: color,
          },
          variant: "forms.controls",
        }}
      />
      {children}
    </Label>
  )
}

Checkbox.propTypes = {
  color: PropTypes.string,
  /** as long as children are present we are applying a `margin-right` to the checkbox */
  space: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

Checkbox.defaultProps = {
  color: "primary",
  space: 2,
}

export default Checkbox
