import React from "react"
import PropTypes from "prop-types"

import { useThemeUI } from "theme-ui"
import Box from "./box"
import Stack from "./stack"
import Icon from "./icon"
import Text from "./text"
import { alpha } from "@theme-ui/color"

const Note = ({ children, title, size, variant, ...restProps }) => {
  const context = useThemeUI()
  const { theme } = context

  const noteColor =
    variant === "info" ? theme.colors["neutral"] : theme.colors["primary"]

  return (
    <Box
      sx={{
        borderRadius: "md",
        fontSize: size,
        position: "relative",
        p: 6,
        bg: alpha(noteColor, 0.2),
      }}
      {...restProps}
    >
      <Box
        p={1}
        sx={{
          bg: noteColor,
          color: "background",
          boxShadow: `0 0 0 6px ${theme.colors["background"]}`,
          borderRadius: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          width: 8,
          height: 8,
          left: -4,
          top: -2,
        }}
      >
        <Icon
          icon={variant === "info" ? "bulb" : "done"}
          color="inherit"
        ></Icon>
      </Box>
      <Stack space={3}>
        {(title || variant === "task") && (
          <Stack space={3}>
            <Text as="span" bold uppercase sans size={0} letterSpacing="2px">
              {title || "Deine Aufgabe"}
            </Text>
            <Box sx={{ width: 4, height: 1, bg: noteColor }} />
          </Stack>
        )}
        {children}
      </Stack>
    </Box>
  )
}

Note.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.oneOf(["info", "task"]),
}

Note.defaultProps = {
  variant: "info",
  size: [3, 3, 4],
}

export default Note
