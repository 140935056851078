import React from "react"
import Stack from "../../../../components/stack"
import Checkbox from "../../../../components/checkbox"
import Note from "../../../../components/note"
import Text from "../../../../components/text"

const OrdnungTask = () => {
  return (
    <Stack space={6}>
      <Note variant="task">
        Wie ist das Gedicht geordnet? Wähle die zutreffenden Lösungen aus. Es
        sind auch mehrere Lösungen möglich.
      </Note>
      <Text sans size={3} color="whisper">
        Das Gedicht ist geordnet durch ...
      </Text>
      <Stack space={3}>
        <Checkbox name="q-01" value="Refrain">
          einen Refrain (eine oder mehrere Zeilen, die immer wieder vorkommen)
        </Checkbox>
        <Checkbox name="q-02" value="Gleiche Vokale">
          gleiche Vokale (zum Beispiel viele Wörter mit a)
        </Checkbox>
        <Checkbox name="q-03" value="Reim">
          einen Reim
        </Checkbox>
        <Checkbox
          name="q-04"
          value="Gleiche Anzahl von Versen in einer Strophe"
        >
          die gleichen Anzahl von Versen in einer Strophe
        </Checkbox>
        <Checkbox name="q-05" value="Bild oder eine Figur">
          ein Bild oder eine Figur
        </Checkbox>
        <Checkbox name="q-06" value="Liste oder Aufzählung">
          eine Liste oder Aufzählung
        </Checkbox>
      </Stack>
    </Stack>
  )
}

export default OrdnungTask
