import React from "react"
import PropTypes from "prop-types"
import Text from "./text"

const Label = ({ children, bold, ...restProps }) => {
  return (
    <Text
      as="label"
      sans
      bold={bold}
      display="flex"
      width="100%"
      cursor="pointer"
      {...restProps}
    >
      {children}
    </Text>
  )
}

Label.propTypes = {
  bold: PropTypes.bool,
}

Label.defaultProps = {
  bold: false,
}

export default Label
